.TimeIndicator-pointer {
    width: 2px;
    display: block;
    position: absolute;
    background-color: #e82a2af7;
    z-index: 100;
    box-shadow: 0px 0px 4px 0px #040404a3;
    top: -20px;
    bottom: -20px;

}
