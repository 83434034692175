.teamList-holder {
    margin: 40px 0;
    position: relative;
}

.teamList-item {
    margin-bottom: 20px;
}

svg {
    pointer-events: none;
}

.main-view {
    margin: 0 auto;
    position: relative;
    transition: all 0.25s linear;
}

.main-view--pizza {
    max-width: 40vw;
}

.main-view--relative {
    margin: 0 5vw;
}

.main-view--relative .teamList-item {
    margin-bottom: 45px;
}


.timeBar {
    height: 50px;
    border-radius: 180px;
    position: relative;
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
}

.timeBar:before {
    content: '';
    display: block;
    height: 50px;
    border-radius: 180px;
    position: relative;
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    filter: blur(5px);
    transition: 0.3s opacity ease-in-out;
}

.timeBar:after {
    content: '';
    display: block;
    top: -78px;
    left: 30px;
    width: 40%;
    margin: 0 auto;
    height: 14px;
    border-radius: 180px;
    position: relative;
    background-color: #3030309e;
}

.timeBar-wrap {
    position: relative;
    max-height: 47px;
    padding: 0 14px;
    top: -49px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timeBar-text {
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
}

.timeBar-text .heavy {
    font-weight: 700;
}

.timeBar-extras {
    display: block;
    opacity: 0;
    color: white;
    position: absolute;
    top: 15px;
    right: -33px;
    transition: opacity 0.25s ease-in-out;
}

.timeBar-extras button {
    background: none;
    border: none;
    cursor: pointer;
    display: block;
}

.timeBar:hover .timeBar-extras {
    opacity: 1;
}

.timeBar--relative:after {
    display: none;
}

.timeBar-wrap--relative {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    top: 4px;
    left: 0;
    right: 0;
    padding: 0 10px; /* might effect the time */
    box-sizing: border-box;
}

.timeBar--relative .timeBar-text {
    margin-left: 20px;
    top: -90px;
    position: relative;
}

.timeBar-wrap--relative .timeBar-wrap--relative--item {
  display: block;
  width: calc(100% / 24);
  margin: 0;
  padding: 0;
  text-align: center;
  color: #000000a3;
  text-shadow: 0px 0px 17px #fff;
  border-left: 1px solid #3030301c;
}

.timeBar-wrap--relative--item--time {
    display: flex;
    flex-direction: column;
    margin: 0;
}


.timeBar-wrap--relative .timeBar-wrap--relative--item:first-child {
    border: none;
}

@media screen and (max-width: 900px) {

    .timeBar-wrap--relative .timeBar-wrap--relative--item {
        border: none;
    }

    .timeBar-wrap--relative .timeBar-wrap--relative--item {
        visibility: hidden;
    }

    .timeBar-wrap--relative .timeBar-wrap--relative--item:nth-child(4n+2) {
        visibility: visible;
    }  
}


@media screen and (max-width: 600px) {
    .timeBar-wrap {
        top: -60px;
        justify-content: center;
    }
    .timeBar-wrap>p {
        padding: 0 5px;
    }
    .timeBar:after {
        top: -68px;
    }

    .main-view--relative {
        transform: rotate(90deg);
    }
    .timeBar-wrap--relative .timeBar-wrap--relative--item {
        visibility: hidden;
    }

}