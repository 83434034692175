.app {
  width: 100%;
}

.teamMate-list {
  margin: 20px 0;
}

.teamMate-form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 10px;
}

.teamMate-form--item {
  padding: 10px 0;
}

@media screen and (max-width: 800px) {
  .teamMate-form {
    flex-direction: column;
    align-items: center;
  }

  .teamMate-form--item > button {
    min-width: 300px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .small-hide {
    display: none;
  }

  .main-view {
    max-width: 100vw;
  }

  

}