#root {
  overflow: hidden;
  display: flex;
  height: 100vh;
  align-items: center;
}

.timebox-globe {
  --globe-size: 70vw;

  position: fixed;
  top: calc(50% - (var(--globe-size) / 2));
  left: calc(50% - (var(--globe-size) / 2));
  width: var(--globe-size);
  height: var(--globe-size);
  border-radius: 50%;
  box-shadow:
    inset 0 0 calc(var(--globe-size) / 6) #fff,
    inset calc(var(--globe-size) / 15) 0 calc(var(--globe-size) / 3.75) #f0f,
    inset calc(var(--globe-size) / -15) 0 calc(var(--globe-size) / 3.75) #0ff,
    inset calc(var(--globe-size) / 15) 0 var(--globe-size) #f0f,
    inset calc(var(--globe-size) / -15) 0 var(--globe-size) #0ff,
    0 0 calc(var(--globe-size) / 6) #fff,
    calc(var(--globe-size) / -30) 0 calc(var(--globe-size) / 3.75) #f0f,
    calc(var(--globe-size) / 30) 0 calc(var(--globe-size) / 3.75) #0ff;
}

.rotate {
  animation: rotating 10s linear infinite;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
} 

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}